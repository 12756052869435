import React, { useState, useEffect } from "react"
import map_france from "../../../../assets/images/map_france.svg"
import Pagination from "../../../../components/pagination/pagination"
import "./index.scss"
import utils from "../../../../utils/utils"
import links from "../../../../utils/links"

const BlockMapFrance = ({ regions, villes }) => {
  const [campagne, setCampagne] = useState("")
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
    }
  }, [])
  const limitItem = 6
  const limitItemPerRow = 3
  const category = [
    { label: "Régions", positions: regions },
    { label: "Villes", positions: villes },
  ]
  const [selectCategory, setSelectCategory] = useState(0)
  const [paginationData, setPaginationData] = useState(
    utils.pagination(category[selectCategory]?.positions?.length, 1, limitItem)
  )
  const [selectItem, setSelectItem] = useState(null)
  const formatElementByPage = (errorCase = 0) => {
    const currentData = category[selectCategory].positions
    const curentPage = paginationData?.currentPage
    let result: any = []
    let resultRow = [[], []]
    if (curentPage !== null || currentData !== undefined) {
      const paginationItem = paginationData?.tabs
      paginationItem?.forEach(index_page => {
        const itemToGet = index_page * limitItem
        const intervalSlice = itemToGet - limitItem
        const dataByIndex = currentData.slice(intervalSlice, itemToGet)
        result.push(dataByIndex)
      })
      let dataMatch
      if (curentPage <= 5) {
        dataMatch = result[curentPage - 1]
      } else if (curentPage > 5 && curentPage <= 10) {
        dataMatch = result[curentPage - 6]
      } else if (curentPage > 10 && curentPage <= 15) {
        dataMatch = result[curentPage - 11]
      } else {
        dataMatch = result[curentPage - 16]
      }

      if (dataMatch?.length > 0)
        dataMatch.forEach(el => {
          const matchIndex = resultRow[0].length == limitItemPerRow ? 1 : 0
          resultRow[matchIndex].push(el)
        })
    }
    return { resultRow: resultRow, result: result }
  }
  const handleChange = page => {
    setPaginationData(
      utils.pagination(category[selectCategory].positions.length, page, 5, 6)
    )
  }
  const handleSelectPosition = position => {
    setSelectItem(position)
    const formatAllData = category[selectCategory].positions
    let matchPage = 0
    formatAllData.forEach((e, i) => {
      if (e.node.name == position.node.name) {
        matchPage = Math.ceil((i + 1) / 6)
        console.log(matchPage)
        console.log(category[selectCategory].positions.length)
      }
      setPaginationData(
        utils.pagination(
          category[selectCategory].positions.length,
          matchPage,
          5,
          6
        )
      )
    })

    // setSelectItem(position)
    // const formatAllData = formatElementByPage().result
    // let matchPage = 0
    // formatAllData.forEach((e, i) => {
    //   const match = e.find(el => el.node.name == position.node.name)
    //   console.log(match)
    //   if (match) {
    //     matchPage = i + 1
    //   }
    // })
    // setPaginationData(
    //   utils.pagination(
    //     category[selectCategory].positions.length,
    //     matchPage,
    //     5,
    //     6
    //   )
    // )
  }
  const getUrl = elm => {
    const haveRegion = elm.node.region ? utils.formatUrl(elm.node.region) : null
    const formatUrl = haveRegion
      ? utils.formatUrl(haveRegion) + "/" + utils.formatUrl(elm.node.name)
      : utils.formatUrl(elm.node.name)
    return "/notre-reseau/" + formatUrl + "/" + campagne
  }
  useEffect(() => {
    setPaginationData(
      utils.pagination(category[selectCategory].positions.length, 1, limitItem)
    )
  }, [selectCategory])

    return (
      <div className="map-container d-f ai-c jc-sb py-3">
        <div className="map-list">
          <div className="d-f ai-fs jc-md-c mb-3">
            {category.map((e, i) => (
              <button
                onClick={() => setSelectCategory(i)}
                key={i}
                className={` btn-category ${
                  selectCategory == i ? "is-active c-1 " : "c-b"
                } `}
              >
                {e.label}
              </button>
            ))}
          </div>
  
          <div className="d-f fd-c">
            <div className="d-f ai-f fd-md-c list">
              {formatElementByPage().resultRow.map((line, key_line) => (
                <div key={key_line} className="d-f fd-c ai-s">
                  {line.map(elem => (
                    <div style={{ padding: "13px 13px 13px 0" }}>
                      <a
                        href={getUrl(elem)}
                        className={`btn-category-map d-f ai-c jc-sb ${
                          selectItem
                            ? selectItem.node.name == elem.node.name
                              ? "is-active bg-1 c-w"
                              : ""
                            : ""
                        } `}
                      >
                        <p className="mb-0 ta-l">{elem.node.name}</p>
                        <span
                          className={
                            selectItem
                              ? selectItem.node.name == elem.node.name
                                ? "icon-next-blanc icon-before-20 ml-1"
                                : "icon-next-violet icon-before-20 ml-1"
                              : "icon-next-violet icon-before-20 ml-1"
                          }
                        ></span>
                      </a>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className="mx-a py-5">
              {paginationData && (
                <Pagination data={paginationData} handleChange={handleChange} />
              )}
            </div>
          </div>
        </div>
        <div className="pr-8 d-md-n">
          <div className="map-img ">
            <img className="" src={map_france}></img>
            {category[selectCategory].positions && (
              <>
                {category[selectCategory].positions.map((position, index) => (
                  <button
                    key={index}
                    onClick={() => handleSelectPosition(position)}
                    style={{
                      top: position.node.field_axe_y + "%",
                      left: position.node.field_axe_ + "%",
                    }}
                    className={`point ${
                      selectItem
                        ? selectItem.node.name == position.node.name
                          ? "is-active"
                          : ""
                        : ""
                    } `}
                  ></button>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    )

}

export default BlockMapFrance
